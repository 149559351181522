export interface ConfigWindow extends Window {
    lgConfig: Config;
}

declare const window: ConfigWindow;

export interface Config {
    releaseId: string;
    deployEnvironment: string;
    sentryFrontendDsn: string;
    kinesisCognitoIdentityId: string;
    kinesisEndpoint: string;
    kinesisRegion: string;
    matomoUrl: string;
    kameleoonSiteCode: string;
    stripePublishable: string;
    facebookAppId: string;
    brazeApiKey: string;
    brazeEndpoint: string;
    mercurePublicUrl: string;
    googleTagManagerId: string;
    classroomGoogleTagManagerId: string;
    googleRecaptchaV3SiteKey: string;
    classroomStatisticsApiKey: string;
    classroomStatisticsEndpoint: string;
    domains: {
        en_TEACHER: string;
        STUDENT: string;
        CLASSROOM: string;
    };
}

let cachedConfig: Config | undefined = window.lgConfig;

export const config = new Proxy<Config>({} as Config, {
    // Define lazy reading of config props:
    get(_, prop: keyof Config) {
        if (!cachedConfig) {
            cachedConfig = window.lgConfig;
        }

        return cachedConfig[prop];
    },
});
