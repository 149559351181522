import { ApolloLink, fromPromise } from '@apollo/client';
import { getAuthCheck } from '@lingoda/auth';
import { getLang } from '@lingoda/env';
import { config } from '@lingoda/config';

interface Params {
    onUnauthorized?: () => void;
}

export default (params?: Params) =>
    new ApolloLink((operation, forward) =>
        fromPromise(getAuthCheck('', params?.onUnauthorized)).flatMap(() => {
            operation.setContext({
                headers: {
                    Accept: 'application/json',
                    'X-Locale': getLang(),
                    'X-Release-Id': config.releaseId,
                },
            });

            return forward(operation);
        }),
    );
