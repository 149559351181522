import { StrictMode, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from '@lingoda/i18n';
import { GraphQLProvider, getGraphQlClient } from '@lingoda/graphql-client';
import { type History, Router, goTo, renderRoutes, teacherLogoutPath } from '@lingoda/router';
import { CssBaseline, Suspense } from '@lingoda/ui';
import { ThemeProvider, theme } from '@lingoda/ui/theme';
import { getDashLang } from '@lingoda/env';
import routes from '../routes';
import { TeacherSnackbarProvider } from './providers/TeacherSnackbarProvider';

interface Props {
    history: History;
}

const Root = ({ history }: Props) => {
    const gqlClient = useMemo(
        () =>
            getGraphQlClient({
                onUnauthorized: redirectToLogin,
            }),
        [],
    );

    return (
        <StrictMode>
            <GraphQLProvider client={gqlClient}>
                <IntlProvider locale={getDashLang()}>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <TeacherSnackbarProvider>
                            <HelmetProvider>
                                <Suspense variant="linear">
                                    <Router history={history}>{renderRoutes(routes)}</Router>
                                </Suspense>
                            </HelmetProvider>
                        </TeacherSnackbarProvider>
                    </ThemeProvider>
                </IntlProvider>
            </GraphQLProvider>
        </StrictMode>
    );
};

const redirectToLogin = () => goTo(teacherLogoutPath());

export default Root;
