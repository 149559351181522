import { ExerciseStatus } from '@lingoda/api';
import { trans } from '@lingoda/i18n';
import { type AcceptedLang, getLang, toUrlLang } from '@lingoda/env';
import { isBookBeforeTrialEnabled } from '@lingoda/feature-flags';
import {
    SupportUrlType,
    getMarketingDomain,
    getSchema,
    getSupportUrl,
    getUrl,
    toMarketingUrl,
} from '@lingoda/urls';
import type { SectionCurriculumSlug, SectionName } from '@lingoda/sections';
import { type ClassType, type Currency, PurchaseType } from '@lingoda/graphql';
import { config } from '@lingoda/config';
import makeParamOptional from './makeParamOptional';
import { pathTrans } from './pathTrans';
import { getTranslatedSectionRouting } from './utils';
import { UrlProvider } from './types';
import withProvider from './withProvider';
import { withMarketingUrl } from './withMarketingUrl';
import type { Path, PathAttributes } from './types';

const getCurrentUrlLang = () => toUrlLang(getLang());

export const accountPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: getUrl('student_home'),
});

export const attendedClassesPath = (studentId: number): PathAttributes => ({
    provider: UrlProvider.External,
    href: getUrl('api_students_classes_pdf', {
        id: studentId.toString(),
    }),
});

export const cefrPath = (studentId: number, moduleId: number): PathAttributes => ({
    provider: UrlProvider.External,
    href: getUrl('api_students_cefr_pdf', {
        id: studentId.toString(),
        moduleId: moduleId.toString(),
    }),
});

export const contactPath = (): string => trans('contact.link', {}, 'public-common');

const placementTestPaths: Record<SectionName, Record<AcceptedLang, string>> = {
    english: {
        en: 'https://lingodagmbh.typeform.com/to/MgMvJKPs',
        de: 'https://lingodagmbh.typeform.com/to/HgMcMD6h',
        fr: 'https://lingodagmbh.typeform.com/to/kBepuj80',
        es: 'https://lingodagmbh.typeform.com/to/BNerOSps',
        ru: 'https://lingodagmbh.typeform.com/to/YS12DnjC',
        pl: 'https://lingodagmbh.typeform.com/to/MgMvJKPs', // TODO: provide correct value
        tr: 'https://lingodagmbh.typeform.com/to/MgMvJKPs', // TODO: provide correct value
        it: 'https://lingodagmbh.typeform.com/to/MgMvJKPs', // TODO: provide correct value
        pt_BR: 'https://lingodagmbh.typeform.com/to/MgMvJKPs', // TODO: provide correct value
    },
    german: {
        en: 'https://lingodagmbh.typeform.com/to/Uxe0uXAS',
        de: 'https://lingodagmbh.typeform.com/to/oTkrDeG7',
        fr: 'https://lingodagmbh.typeform.com/to/FzkB81gO',
        es: 'https://lingodagmbh.typeform.com/to/UQYyNX4D',
        ru: 'https://lingodagmbh.typeform.com/to/hGqQF2fl',
        pl: 'https://lingodagmbh.typeform.com/to/Uxe0uXAS', // TODO: provide correct value
        tr: 'https://lingodagmbh.typeform.com/to/Uxe0uXAS', // TODO: provide correct value
        it: 'https://lingodagmbh.typeform.com/to/Uxe0uXAS', // TODO: provide correct value
        pt_BR: 'https://lingodagmbh.typeform.com/to/Uxe0uXAS', // TODO: provide correct value
    },
    french: {
        en: 'https://lingodagmbh.typeform.com/to/AQCZNC7v',
        de: 'https://lingodagmbh.typeform.com/to/acQjEqTh',
        fr: 'https://lingodagmbh.typeform.com/to/OHf8b9Bo',
        es: 'https://lingodagmbh.typeform.com/to/NRCWqbbJ',
        ru: 'https://lingodagmbh.typeform.com/to/aUmlksvO',
        pl: 'https://lingodagmbh.typeform.com/to/AQCZNC7v', // TODO: provide correct value
        tr: 'https://lingodagmbh.typeform.com/to/AQCZNC7v', // TODO: provide correct value
        it: 'https://lingodagmbh.typeform.com/to/AQCZNC7v', // TODO: provide correct value
        pt_BR: 'https://lingodagmbh.typeform.com/to/AQCZNC7v', // TODO: provide correct value
    },
    spanish: {
        en: 'https://lingodagmbh.typeform.com/to/HICmzSPU',
        de: 'https://lingodagmbh.typeform.com/to/XBcHHqya',
        fr: 'https://lingodagmbh.typeform.com/to/rbUTqWLT',
        es: 'https://lingodagmbh.typeform.com/to/wY3rdxpr',
        ru: 'https://lingodagmbh.typeform.com/to/VfNdb9en',
        pl: 'https://lingodagmbh.typeform.com/to/HICmzSPU', // TODO: provide correct value
        tr: 'https://lingodagmbh.typeform.com/to/HICmzSPU', // TODO: provide correct value
        it: 'https://lingodagmbh.typeform.com/to/HICmzSPU', // TODO: provide correct value
        pt_BR: 'https://lingodagmbh.typeform.com/to/HICmzSPU', // TODO: provide correct value
    },
    italian: {
        en: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=en&customsource=assessment_test',
        de: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=de&customsource=assessment_test',
        fr: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=fr&customsource=assessment_test',
        es: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=es&customsource=assessment_test',
        ru: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=ru&customsource=assessment_test',
        pl: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=pl&customsource=assessment_test',
        tr: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=tr&customsource=assessment_test',
        it: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=it&customsource=assessment_test',
        pt_BR: 'https://lingodagmbh.typeform.com/to/dv3hNwMT#locale=pt&customsource=assessment_test',
    },
};

export const placementTestPath = (section: SectionName) => ({
    provider: UrlProvider.External,
    href: placementTestPaths[section]?.[getLang()] || placementTestPaths.english.en,
});

export const corporatePath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: toMarketingUrl(getUrl('corporate')),
});

export const businessEnglishPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: toMarketingUrl(getUrl('business_english')),
});

export const marketingWebPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: `${getSchema()}//${getMarketingDomain()}/${getCurrentUrlLang()}`,
});

export const cefrMarketingPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: toMarketingUrl(getUrl('cefr')),
});

export const imprintPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: toMarketingUrl(getUrl('imprint')),
});

export const privacyPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: toMarketingUrl(getUrl('terms')),
});

export const termsPath = (): PathAttributes => ({
    provider: UrlProvider.External,
    href: toMarketingUrl(getUrl('privacy')),
});

export const blogHomePath = () => `https://blog.lingoda.com/${getCurrentUrlLang()}`;

export const contentCreatorsPath = () =>
    `https://www.lingoda.com/${getCurrentUrlLang()}/p/content-creators/`;

export const getStartedFlowPath = () =>
    config.deployEnvironment === 'production'
        ? `https://www.lingoda.com/${getCurrentUrlLang()}/get-started/`
        : registerPath();

export const faqPath = () => getSupportUrl(SupportUrlType.Home);

export const cancellationPolicyPath = () => getSupportUrl(SupportUrlType.CancelClass);

export const checkoutPath = (url: string): PathAttributes => ({
    provider: UrlProvider.External,
    href: url,
});

interface SectionParams {
    section: SectionName;
}

interface SetPasswordParams {
    token: string;
}

type ResetPasswordParams = SetPasswordParams;
type SetPasswordForNewAccountParams = SetPasswordParams;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isSectionParam = (obj: any): obj is SectionParams =>
    obj && typeof obj === 'object' && obj.section;

interface RouterPathFunction<P> {
    (params?: P, locale?: AcceptedLang): Path;
    raw: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const translateSection = <P extends SectionParams, F extends RouterPathFunction<P>>(fn: any) => {
    const wrapped: RouterPathFunction<P> = (params?: P, locale?: AcceptedLang): Path => {
        let modified = params;
        if (isSectionParam(params)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const section: SectionName = getTranslatedSectionRouting(params.section, locale) as any;
            modified = { ...params, section };
        }

        return fn(modified, locale);
    };

    wrapped.raw = fn.raw;

    return wrapped as F;
};

export const sectionRedirectPath = (section: SectionName): PathAttributes => ({
    provider: UrlProvider.External,
    href: toMarketingUrl(getUrl('section_home', { section: getTranslatedSectionRouting(section) })),
});

export const socialRegisterPath = pathTrans('social_register');

export const socialConfirmPath = pathTrans('social_login_connect_confirmed');

export const socialLoginConfirmMobilePath = pathTrans<{ token: string }>(
    'social_login_connect_confirmed_mobile',
);

export const socialRegisterConfirmMobilePath = pathTrans<{ token: string }>(
    'social_register_confirmed_mobile',
);

export const loginPath = pathTrans('user_security_login');

export const defaultAppPath = (function () {
    const raw = '/';
    const fn = () => raw;
    fn.raw = raw;

    return fn;
})();

export const logoutPath = pathTrans('student_logout');

export const resetPasswordPath = pathTrans<ResetPasswordParams>('user_resetting_reset');
export const setPasswordForNewAccountPath = pathTrans<SetPasswordForNewAccountParams>(
    'user_set_password_for_new_account',
);

type FinalizeDisconnectParams = ResetPasswordParams & {
    service: string;
};

export const finalizeDisconnectPath = pathTrans<FinalizeDisconnectParams>(
    'social_initiate_disconnect_service',
);

export const forgotPasswordPath = pathTrans('forgot_password');
export const forgotPasswordSuccessfulPath = pathTrans('forgot_password_successful');

export const referralPath = pathTrans<{ code?: string }>('referral');
export const fullReferralPath = (params: { code: string }): string => getUrl('referral', params);

// we are keeping old route so store doesn't have to be changed
export const productOrderPath = pathTrans<{ productVariantId: number }>('product_order');

export const productPurchasePath = pathTrans('product_purchase');

export const packagePurchasePath = pathTrans<{
    packageId: string;
    currency: string;
    trial: boolean;
    section: string;
}>('package_purchase');

export const freeTrialPurchasePath = pathTrans<{ packageId: string }>('free_trial_purchase');

export const registerPath = pathTrans('register');

interface RegisterSectionParams extends SectionParams {
    curriculum?: SectionCurriculumSlug;
}

export const directRegisterSectionPath = translateSection(
    pathTrans<RegisterSectionParams | void>('direct_register_section'),
);

export const m2mSignupSectionPath = translateSection(
    pathTrans<SectionParams | void>('m2m_signup_section'),
);

export const m2mRegisterSectionPath = translateSection(
    pathTrans<SectionParams | void>('m2m_register_section'),
);

export const socialRegisterConfirmPath = translateSection(
    pathTrans<SectionParams | void>('social_register_confirm'),
);

export const errorLanguagePath = translateSection(
    pathTrans<SectionParams | undefined>('error_language'),
);

export const completeAccountWelcomePath = pathTrans('complete_account_setup');
export const completeAccountSetPasswordPath = pathTrans('complete_account_set_password');

const reportErrorPaths: Record<AcceptedLang, string> = {
    en: 'https://forms.gle/y6RHpSoRBqhgfFM58',
    de: 'https://forms.gle/uRvxb7WLmFpUZLPg9',
    ru: 'https://forms.gle/h3UXiyKFnm1XMAHm9',
    fr: 'https://forms.gle/G6Dk5rfV3JHwbbhb7',
    es: 'https://forms.gle/e3hsWR6XKRc4i8ox6',
    pl: 'https://forms.gle/y6RHpSoRBqhgfFM58', // TODO: provide correct value
    tr: 'https://forms.gle/y6RHpSoRBqhgfFM58', // TODO: provide correct value
    it: 'https://forms.gle/y6RHpSoRBqhgfFM58', // TODO: provide correct value
    pt_BR: 'https://forms.gle/y6RHpSoRBqhgfFM58', // TODO: provide correct value
};

export const reportErrorPath = () => ({
    provider: UrlProvider.External,
    href: reportErrorPaths[getLang()],
});

const withStudentProvider = withProvider(UrlProvider.Student);

export const studentHomePath = withStudentProvider(pathTrans('student_home'));

export const studentAchievementsPath = withStudentProvider(pathTrans('student_achievements'));

export const studentEmailVerificationPath = withStudentProvider(
    pathTrans('student_email_verification'),
);

export const studentClassesPath = withStudentProvider(pathTrans('lingoda.student.classes'));

export const studentChallengePath = withStudentProvider(
    pathTrans<{ challengeName: string }>('lingoda.student.challenge'),
);

export const studentBookingPath = withStudentProvider(pathTrans('lingoda.student.booking'));

export const studentClassDetailsPath = withStudentProvider(
    pathTrans<{ class_id: string; initBooking?: string }>('lingoda.student.classDetails'),
);

export const studentLessonDetailsPath = withStudentProvider(
    pathTrans<{ lessonId: number; type?: ClassType; date?: string }>(
        'lingoda.student.lessonDetails',
    ),
);
export enum SettingsPages {
    Account = 'account',
    TimeAndLanguage = 'time-and-language',
    Notifications = 'notifications',
    Learning = 'learning',
    Membership = 'membership',
    Referrals = 'referrals',
    Billing = 'billing',
    CalendarConnection = 'calendar-connection',
    None = '',
}
export const studentSettingsPath = withStudentProvider(
    pathTrans<{ page: SettingsPages } | void>('lingoda.student.settings', {
        defaultParams: { page: SettingsPages.Account },
    }),
);
export const studentCourseOverviewPath = withStudentProvider(
    pathTrans<{ moduleName?: string; hash?: string } | void>('lingoda.student.courseOverview'),
);
export const studentPreClassPath = withStudentProvider(
    pathTrans<{ classId: string }>('lingoda.student.preClassChecklist'),
);
export const studentTeacherPath = withStudentProvider(
    pathTrans<{ uid: string }>('lingoda.student.teacher'),
);
export const externalZoomDownloadPath = () => 'https://zoom.us/download';
export const externalZoomTestPath = () => 'https://lingoda.zoom.us/test';
export const externalZoomContactPath = () => getSupportUrl(SupportUrlType.Class);
export const externalZoomSupportPath = () => 'https://support.zoom.us';
export const externalTeacherDidntShowUpPath = () => getSupportUrl(SupportUrlType.Class);
export const studentStorePath = withStudentProvider(pathTrans('lingoda.student.store'));
export const creditsConversionPath = withStudentProvider(
    pathTrans('lingoda.student.creditsConversion'),
);

export interface SubscriptionsStorePathQuery {
    classType?: ClassType.Group | ClassType.Individual;
    currency?: Currency;
    curriculumSlug?: string;
    planType?: PurchaseType.Marathon | PurchaseType.Monthly;
}
export const subscriptionsStorePath = pathTrans<SubscriptionsStorePathQuery | void>(
    'lingoda.student.subscriptionsStore',
);

export const leadFlowPath = pathTrans('lingoda.student.leadConversionFlow');

export const accountClosedPath = withStudentProvider(
    pathTrans('lingoda.student.store.accountClosed'),
);

export const changeLearningLanguageStorePath = pathTrans<{
    section: SectionName;
    curriculum: number;
}>('lingoda.student.changeLearningLanguageStorefront');

export const changeLearningLanguageSuccessPath = withStudentProvider(
    pathTrans('lingoda.student.changeLearningLanguageSuccess'),
);

export const cancelSurveyPath = withStudentProvider(
    pathTrans<{ subscription: number }>('lingoda.student.store.cancelSurvey'),
);
export const canceledCoursePath = withStudentProvider(
    pathTrans<{ subscription: number }>('lingoda.student.store.canceledCourse'),
);
export const refusedPaymentPath = withStudentProvider(
    pathTrans<{ subscription: number }>('lingoda.student.store.refusedPayment'),
);

export const studentReactivateCoursePath = withStudentProvider(
    pathTrans('lingoda.student.store.reactivateCourse'),
);
export const studentReactivatedPath = withStudentProvider(
    pathTrans('lingoda.student.store.reactivated'),
);

export const studentChangedCoursePath = withStudentProvider(
    pathTrans('lingoda.student.store.changedCourse'),
);
export const studentCreditsAddedPath = withStudentProvider(
    pathTrans('lingoda.student.store.creditsAdded'),
);
export const studentChangedPaymentMethodPath = withStudentProvider(
    pathTrans('lingoda.student.store.changedPaymentMethod'),
);
export const confirmCancelPath = withStudentProvider(
    pathTrans<{
        subscription: number;
        cancelReason: string;
    }>('lingoda.student.store.confirmCancel'),
);
export const cancelCourseChangePath = withStudentProvider(
    pathTrans<{
        subscription: number;
        cancelReason: string;
    }>('lingoda.student.store.cancelCourseChange'),
);
export const cancelCoursePausePath = withStudentProvider(
    pathTrans<{
        subscription: number;
        cancelReason: string;
    }>('lingoda.student.store.cancelCoursePause'),
);

export const studentM2MCheckoutPath = withStudentProvider(
    pathTrans<{ purchaseId: string }>('student_store_m2m_checkout'),
);

export const studentCreditsCheckoutPath = withStudentProvider(
    pathTrans<{ purchaseId: string }>('student_store_credits_checkout'),
);

export const getCheckoutPath = (purchase: { type: PurchaseType; id: string }) => {
    switch (purchase.type) {
        case PurchaseType.LearningTeam:
            return studentTeamsCheckoutPath;
        case PurchaseType.Sprint:
            return studentSprintCheckoutPath;
        case PurchaseType.ExtraCredits:
            return studentCreditsCheckoutPath;
        default:
            return studentM2MCheckoutPath;
    }
};

export const studentPaymentPendingPath = withStudentProvider(
    makeParamOptional(
        pathTrans<{ purchaseId: string | undefined; paymentPurpose?: string; successUrl?: string }>(
            'student_store_paymentPending',
        ),
        'purchaseId',
    ),
);

export const studentPaymentFailedPath = withStudentProvider(
    makeParamOptional(
        pathTrans<{ purchaseId: string | undefined; paymentPurpose?: string }>(
            'student_store_paymentFailed',
        ),
        'purchaseId',
    ),
);

export const changeSubscriptionFailedPath = withStudentProvider(
    pathTrans('student_change_subscription_failed'),
);

export const studentPracticePath = withStudentProvider(
    pathTrans<{ status: ExerciseStatus } | void>('student_practice', {
        defaultParams: { status: ExerciseStatus.PENDING },
    }),
);

export const studentHomeworkPath = withStudentProvider(
    pathTrans<{ lessonId: number }>('student_homework'),
);

export const studentProfilePath = withStudentProvider(pathTrans('student_profile'));

export const studentQuizPath = withStudentProvider(pathTrans<{ lessonId: number }>('student_quiz'));

export const studentQuizNextPath = pathTrans('student_quiz_next');

export const studentPracticeSequencePath = withStudentProvider(
    pathTrans<{ lessonId: number }>('student_practice_sequence'),
);

export const studentLingobitesSupportPath = () => getSupportUrl(SupportUrlType.Lingobites);

export const studentExercisesPath = pathTrans<{ learningUnitId: number }>('student_exercises');

export const studentFlashcardsPath = pathTrans<{ learningUnitId: number }>('student_flashcards');

export const studentVocabularyPath = withStudentProvider(
    pathTrans<{ lessonId: number }>('student_vocabulary'),
);

export const studentGrammarPath = withStudentProvider(
    pathTrans<{ lessonId: number }>('student_grammar'),
);

export const studentGrammarItemPath = withStudentProvider(
    pathTrans<{ grammarId: string }>('student_grammar_item'),
);

/**
 * Classroom paths
 */
export const classroomJoinClassPath = pathTrans<{ classUid: string }>('classroom_class', {
    absolute: true,
});

/**
 * Sprint paths
 */
export const registerSectionSprintPath = translateSection(
    pathTrans<RegisterSectionParams | void>('register_sprint_section'),
);

export const sprintSignupPath = translateSection(
    pathTrans<RegisterSectionParams | void>('sprint_signup_section'),
);

export const bookingPreviewPath = withStudentProvider(
    pathTrans<{ redirect?: string }>('booking_preview'),
);

export const studentSprintCheckoutPath = withStudentProvider(
    pathTrans<{ purchaseId: string }>('sprint_student_store_checkout'),
);

export const sprintPackagePurchasePath = pathTrans<{
    packageId: string;
    currency: string;
    trial: boolean;
}>('sprint_package_purchase');

/**
 * Teams paths
 */
export const studentTeamsPackagePurchasePath = pathTrans<{
    packageId: string;
    courseId: string;
    currency: string;
    section: string;
    certs?: string;
}>('teams_package_purchase');

export const studentTeamsCheckoutPath = withStudentProvider(
    pathTrans<{ purchaseId: string }>('teams_store_checkout'),
);

export const teamsRegisterSectionPath = translateSection(
    pathTrans<SectionParams | void>('register_teams_section'),
);

export const teamsSignupPath = translateSection(
    pathTrans<SectionParams | void>('teams_signup_section'),
);

export const teamsPricingPath = withMarketingUrl(pathTrans('teams_pricing', { absolute: true }));

export const externalTeamsSurveyLink = () => ({
    provider: UrlProvider.External,
    href: 'https://www.surveymonkey.com/r/TY9DCYL',
});

/**
 * Onboarding paths
 */
export const onboardingPath = pathTrans('student_onboarding');
export const onboardingSurveyPath = pathTrans('student_onboarding_survey');
export const onboardingWelcomePath = pathTrans('student_onboarding_welcome');
export const onboardingTimezonePath = pathTrans('student_onboarding_timezone');
export const onboardingLevelPath = pathTrans('student_onboarding_level');
export const onboardingLevelPickPath = pathTrans('student_onboarding_level_pick');
export const onboardingLevelPlacedPath = pathTrans('student_onboarding_level_placed');
export const onboardingLevelSubPath = pathTrans<{
    level: string;
    sublevel?: string;
} | void>('student_onboarding_level_sub');
export const onboardingBookingPath = pathTrans('student_onboarding_booking');
export const onboardingBookingSkipPath = pathTrans('student_onboarding_booking_skip');
export const onboardingCongratulationsPath = pathTrans('student_onboarding_congratulations');

export const postCheckoutPath = pathTrans('student_post_checkout');
export const postCheckoutWelcomePath = pathTrans('student_post_checkout_welcome');
export const postCheckoutProficiencyPath = pathTrans('student_post_checkout_proficiency');
export const postCheckoutProficiencyFindYourLevelPath = pathTrans(
    'student_post_checkout_proficiency_find_your_level',
);
export const postCheckoutProficiencyLevelPath = pathTrans(
    'student_post_checkout_proficiency_level',
);
export const postCheckoutProficiencySubLevelPath = pathTrans<{
    level: string;
} | void>('student_post_checkout_proficiency_sub_level');
export const postCheckoutProficiencySummaryPath = pathTrans<{
    level: string;
    sublevel: string;
} | void>('student_post_checkout_proficiency_summary');
export const postCheckoutBookClassPath = pathTrans('student_post_checkout_book_class');
export const postCheckoutCongratulationsPath = pathTrans('student_post_checkout_congratulations');

/**
 * Getting Started paths
 */
export enum GetStartedStep {
    Level = 'level',
    ClassType = 'class-type',
    FirstClass = 'first-class',
    Price = 'price',
    Purchase = 'purchase',
    Congrats = 'congratulations',
    Survey = 'survey',
}
export const getStartedPath = pathTrans('get_started');
export const getStartedStepNamePath = pathTrans<{ stepName: string }>('get_started_step_name');
export const getStartedFooterPaths = {
    businessEnglish: withMarketingUrl(pathTrans('business_english', { absolute: true })),
    english: withMarketingUrl(pathTrans('english', { absolute: true })),
    german: withMarketingUrl(pathTrans('german', { absolute: true })),
    french: withMarketingUrl(pathTrans('french', { absolute: true })),
    spanish: withMarketingUrl(pathTrans('spanish', { absolute: true })),
    italian: withMarketingUrl(pathTrans('italian', { absolute: true })),
    corporate: withMarketingUrl(pathTrans('corporate', { absolute: true })),
    aboutUs: withMarketingUrl(pathTrans('about_us', { absolute: true })),
    careers: withMarketingUrl(pathTrans('careers', { absolute: true })),
    press: withMarketingUrl(pathTrans('press', { absolute: true })),
    research: withMarketingUrl(pathTrans('research', { absolute: true })),
    linguaskillFromCambridge: withMarketingUrl(
        pathTrans('linguaskill_from_cambridge', { absolute: true }),
    ),
    howItWorks: withMarketingUrl(pathTrans('how_it_works', { absolute: true })),
    resources: withMarketingUrl(pathTrans('resources', { absolute: true })),
    faq: withMarketingUrl(pathTrans('faq', { absolute: true })),
    affiliateProgram: withMarketingUrl(pathTrans('affiliate_program', { absolute: true })),
    becomeATeacher: withMarketingUrl(pathTrans('become_a_teacher', { absolute: true })),
    blog: blogHomePath,
    contactPath,
    contentCreatorsPath,
};

export const onboardingEntrypointPath = () => {
    if (isBookBeforeTrialEnabled()) {
        return getStartedStepNamePath({ stepName: GetStartedStep.Congrats });
    }

    return onboardingSurveyPath();
};

/**
 * Social paths
 */
export const linkedInSharePath = (url: string) =>
    encodeURI(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`);

interface TwitterSharePathParams {
    text?: string;
    hashtags?: string[];
}

export const twitterSharePath = (
    url: string,
    { text = '', hashtags = [] }: TwitterSharePathParams,
) =>
    encodeURI(
        `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=${hashtags?.join(',')}`,
    );

export const chromeBrowserPath = () => encodeURI('https://www.google.com/chrome/');

export const enableCookiesPath = () =>
    encodeURI('https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform=Desktop');

const writingLinks = {
    english: {
        A2: 'https://zippy-primrose-f7a.notion.site/A2-Writing-Exercises-11fa4fe274dc41138e86b2e0cd4b37ff',
        B2: 'https://zippy-primrose-f7a.notion.site/B2-Writing-Exercises-e26703192f7d432daf6b050a4fb3bf41',
    },
    spanish: {
        A2: 'https://zippy-primrose-f7a.notion.site/A2-Writing-Exercises-3d0ecbbf19544a5a84d4abc8acea3172',
        B2: 'https://zippy-primrose-f7a.notion.site/B2-Writing-Exercises-5ae40833643d4763af4e06bd9c072446',
    },
};

export const getWritingPath = (sectionName?: SectionName, cefrLevelName?: string) => {
    if (!sectionName || (sectionName !== 'english' && sectionName !== 'spanish')) {
        return;
    }
    if (!cefrLevelName || (cefrLevelName !== 'A2' && cefrLevelName !== 'B2')) {
        return;
    }

    return {
        provider: UrlProvider.External,
        href: writingLinks[sectionName][cefrLevelName],
    };
};

export const externalNewFeaturesBlogPostPath = () => ({
    provider: UrlProvider.External,
    href: 'https://blog.lingoda.com/en/lingoda-new-features/',
});

export const externalRolloverPauseLetUsKnowPath = () => ({
    provider: UrlProvider.External,
    href: 'https://docs.google.com/forms/d/e/1FAIpQLSf9qc5Zlf2KoQW7T3_ClDvNQUWGP2CSm54OndmRWvghb5LfdQ/viewform',
});

export const getOrientationCoursePath = (sectionName: SectionName): string => {
    const orientationCourseIds: Record<SectionName, string> = {
        english: '47ac16c0-3308-4045-b3b5-da892e53950f',
        german: '909b54e0-462c-476e-a8ec-b4cbe43e961a',
        french: '5bdd97d8-141c-46ec-8cf9-eb3a20f1f7eb',
        spanish: 'b3ffc01f-a7d3-4072-ad4a-95c1f6473010',
        italian: 'b3ffc01f-a7d3-4072-ad4a-95c1f6473010', // TODO: provide correct value
    };

    const path = `https://auth.easygenerator.com/api/auth/oauth/lingoda?callbackUrl=https://elearning.easygenerator.com/`;

    return path + orientationCourseIds[sectionName];
};

export const giveFeedbackUrl = {
    quiz: 'https://lingodagmbh.typeform.com/to/T0Sunz6b',
    flashcards: 'https://lingodagmbh.typeform.com/to/qcEvOicW',
    vocabulary: 'https://lingodagmbh.typeform.com/to/KAbYsFf2',
    lessonSummary: 'https://lingodagmbh.typeform.com/to/XYEcWilr',
    exercises: 'https://lingodagmbh.typeform.com/to/iO430N1e',
    practiceSequence: 'https://lingodagmbh.typeform.com/to/oHuJyjAo',
};
