import { ApolloClient, ApolloLink } from '@apollo/client';
import { SentryLink } from '@lingoda/monitor';
import getGraphQLCache from './cache';
import { getAuthRestLink, getErrorLink, getRestLink, getRetryLink, getUploadLink } from './links';

interface Params {
    onUnauthorized?: () => void;
}

export const getGraphQlClient = (params: Params = {}) =>
    new ApolloClient({
        cache: getGraphQLCache(),
        link: ApolloLink.from([
            new SentryLink(),
            getErrorLink(params),
            getRetryLink(),
            getAuthRestLink(params),
            getRestLink(),
            monolithLink,
        ]),
        connectToDevTools: process.env.NODE_ENV !== 'production',
    });

const monolithLink = getUploadLink({ uri: '/graphql' });
