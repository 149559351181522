import { createAction, makeActionTrackable } from '@lingoda/core';

export interface Changes {
    fullName: string;
    email: string;
    birthCity?: string;
    birthCountry?: string;
}

export default makeActionTrackable(
    createAction('user/UPDATE_PROFILE', (userId: number, changes: Changes) => ({
        changes,
        userId,
    })),
);
