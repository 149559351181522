import { offsetLimitPagination } from '@apollo/client/utilities';
import { pagination } from '@lingoda/graphql';
import Appointment from './Appointment';
import { ActivityChallenge } from './ActivityChallenge';
import { Image } from './Image';
import { Klass } from './Klass';
import { appointmentsList } from './appointmentsList';
import exercises from './exercises';
import PackageVariant from './packageVariant';
import PracticeItem from './PracticeItem';
import practiceItems from './practiceItems';
import recommendedClasses from './recommendedClasses';
import SectionCurriculum from './sections';
import VocabularyItem from './vocabularyItem';
import flashcardTotals from './flashcardTotals';
import { classes } from './classes';
import { StudentSubscriptionInfo } from './StudentSubscriptionInfo';
import type { TypePolicies } from '@apollo/client';

const typePolicies: TypePolicies = {
    ActivityChallenge,
    Appointment,
    Country: {
        keyFields: ['code'],
    },
    Image,
    StudentLearningTeam: {
        keyFields: ['courseId'],
    },
    Klass,
    PracticeItem,
    SectionCurriculum,
    StudentSubscriptionInfo,
    Variant: PackageVariant,
    VocabularyItem,
    StudentYearlyActivitySummary: {
        keyFields: ['year'],
    },
    Query: {
        fields: {
            country: (_, { args, toReference }) =>
                toReference({
                    __typename: 'Country',
                    code: args?.code,
                }),
            exercises,
            practiceItems,
            appointmentsList,
            recommendedClasses,
            recommendedClass: (_, { args, toReference }) =>
                toReference({
                    __typename: 'RecommendedClass',
                    id: args?.classId,
                }),
            onboardingClass: (_, { args, toReference }) =>
                toReference({
                    __typename: 'Klass',
                    uid: args?.classId,
                }),
            section: (_, { args, toReference }) =>
                toReference({
                    __typename: 'Section',
                    id: args?.id,
                }),
            studentModule: (_, { args, toReference }) =>
                toReference({
                    __typename: 'StudentModule',
                    id: args?.id,
                }),
            class: (_, { args, toReference }) =>
                toReference({
                    __typename: 'Klass',
                    uid: args?.id,
                }),
            flashcardTotals,
            teacherInvoices: offsetLimitPagination(),
            teacherLearningMaterials: pagination(),
            classes,
        },
    },
};

export { typePolicies };
