import { useCallback } from 'react';
import { type Event, addEvent, sectionsToCategory, sectionsToLabel } from '../utils';
import { useTrackingContext } from './useTrackingContext';

export type UiEventFunc = <A extends Omit<Event, 'id' | 'action' | 'label' | 'category'>>(
    action: string,
    values?: A,
) => Promise<unknown>;

export const useUiEvent = () => {
    const { sections } = useTrackingContext();

    return useCallback<UiEventFunc>(
        (action, values) => {
            if (!sections.length) {
                return Promise.reject();
            }

            const category = sectionsToCategory(sections);

            return addEvent({
                action,
                category,
                label: sectionsToLabel(sections),
                ...values,
                metadata: {
                    category,
                    ...(values?.metadata || {}),
                },
            });
        },
        [sections],
    );
};
