import { config } from '@lingoda/config';
import type { GoogleWindow } from '../common';

declare const window: GoogleWindow;

export default (
    gtmApiKey: 'classroomGoogleTagManagerId' | 'googleTagManagerId' = 'googleTagManagerId',
): void => {
    if (window) {
        window.dataLayer = window.dataLayer || [];

        if (!config[gtmApiKey]) {
            return;
        }

        // GTM
        ((w: GoogleWindow, d: Document, s: string, l: 'dataLayer', i: string) => {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s) as HTMLScriptElement;
            const dl = l !== 'dataLayer' ? `&l=${l}` : '';
            j.async = true;
            j.src = `//www.googletagmanager.com/gtm.js?id=${i}${dl}`;
            f.parentNode?.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', config[gtmApiKey]);
    }
};
