import { colorsPalette } from './colorsPalette';
import type { ComponentsVariants } from '@mui/material/styles';

export const getButtonVariants = (): ComponentsVariants['MuiButton'] => [
    {
        props: { variant: 'outlined', color: 'grayscale' },
        style: {
            color: colorsPalette.blackSolid[100],
            borderColor: colorsPalette.blackAlpha[20],
            '&:disabled': {
                color: colorsPalette.blackAlpha[20],
            },
            '&:hover': {
                backgroundColor: colorsPalette.blackAlpha[5],
                borderColor: colorsPalette.blackAlpha[20],
            },

            '.MuiButtonGroup-root &': {
                '&.MuiButtonGroup-firstButton': {
                    '&:hover': {
                        borderRightColor: 'transparent',
                    },
                },
                '&.MuiButtonGroup-middleButton': {
                    '&:hover': {
                        borderRightColor: 'transparent',
                    },
                },
            },
        },
    },
    {
        props: { variant: 'contained', color: 'grayscale' },
        style: {
            color: colorsPalette.blackSolid[100],
            background: colorsPalette.whiteSolid[100],
            borderColor: colorsPalette.blackAlpha[20],
            borderWidth: 1,
            '&:hover': {
                backgroundColor: colorsPalette.blackSolid[4],
            },
        },
    },
];
